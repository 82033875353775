import React from "react";

import { createIcon } from "@chakra-ui/react";

export enum CustomIcons {
  /* Status Icons */
  Armor = "armor",
  BatteryEmpty = "batteryempty",
  BatteryFull = "batteryfull",
  Break = "break",
  CardboardBox = "cardboardbox",
  ConcentricCrescents = "concentriccrescents",
  DoubleRingedOrb = "doubleringedorb",
  Shield = "shield",
  Stress = "stress",
  /* Navigation Icons */
  Characters = "characters",
  Home = "home",
  Profile = "profile",
  World = "world",
  /* Action Icons */
  LeaveGame = "leavegame",
  Kebab = "kebab",
}

interface IconInterface {
  viewBox?: string;
  pathPropsList: IconPathProps[];
}

interface IconPathProps {
  d: string;
  fill?: string;
}

type Icon = {
  [key in CustomIcons]: IconInterface;
};

const Icons: Icon = {
  [CustomIcons.Armor]: {
    pathPropsList: [
      {
        d: "M156.7 25.83L89 39.38c-.1 58.57-1.74 119.32-43.49 167.22C104.4 246.5 189 260.7 247 248.8v-99L108.3 88.22l7.4-16.44L256 134.2l140.3-62.42 7.4 16.44L265 149.8v99c58 11.9 142.6-2.3 201.5-42.2-41.8-47.9-43.4-108.65-43.5-167.22l-67.7-13.55c-12.9 13.88-20.6 28.15-32.9 40.53C308.9 79.78 289.5 89 256 89c-33.5 0-52.9-9.22-66.4-22.64-12.3-12.38-20-26.65-32.9-40.53zM53.88 232.9C75.96 281 96.07 336.6 102.7 392.8l65 22.8c4.2-52.7 28.2-104 63.7-146.1-55.1 6.3-122.7-5.8-177.52-36.6zm404.22 0c-54.8 30.8-122.4 42.9-177.5 36.6 35.5 42.1 59.5 93.4 63.7 146.1l65.2-22.9c6.6-56.8 26.6-111.8 48.6-159.8zM256 269c-40.5 43.1-67.7 97.9-70.7 152.7l61.7 21.6V336h18v107.3l61.7-21.6c-3.1-54.8-30.2-109.6-70.7-152.7zm151.7 143.4L297 451.1v18.8l110.2-44.1c.1-4.5.3-8.9.5-13.4zm-303.3.1c.3 4.5.4 8.9.5 13.4l110.1 44v-18.7l-110.6-38.7zM279 457.4l-23 8.1-23-8v19.6l23 9.2 23-9.2v-19.7z",
      },
    ],
  },
  [CustomIcons.BatteryEmpty]: {
    pathPropsList: [
      {
        d: "M226 16c-24.93 0-45 13.38-45 30v15h-45c-29.547 0-60 30.453-60 60v315c0 29.547 30.453 60 60 60h240c29.547 0 60-30.453 60-60V121c0-29.547-30.453-60-60-60h-45V46c0-16.62-20.07-30-45-30h-60zm-90 105h240v315H136V121zm120 30v105h-75l75 150V301h75l-75-150z",
      },
    ],
  },
  [CustomIcons.BatteryFull]: {
    pathPropsList: [
      {
        d: "M230.218 16c-14.245 0-51.563 11.946-51.563 26.718v26.718h-51.093C99.072 69.436 76 93.326 76 122.874V442.56C76 472.11 99.072 496 127.563 496h256.875c28.49-.002 51.562-23.892 51.562-53.44V122.874c0-29.547-23.072-53.437-51.563-53.437h-51.093V42.718c0-14.774-37.317-26.718-51.562-26.718H230.22zM256 122.875V256h102.657L256 442.563V309.438H153.343L256 122.875z",
      },
    ],
  },
  [CustomIcons.Break]: {
    pathPropsList: [
      {
        d: "M326.594 14.97l-39.78 45.468L285.717 170l52.56-95.78-11.686-59.25zM204.438 27.78l-8.25 72.095 53.03 95.063-1.562-108.875-43.22-58.282zM69.75 61.157l35.438 74.28 105.187 77.75-37.72-78.874L69.75 61.156zm365.72 20.25l-66.564 28.875-55.812 93.5 93.5-55.81 28.875-66.564zm.28 91.094l-75 53.063L449.53 223 495 180l-59.25-7.5zm-159.063 21.375l-11.968 38.28-35.908-29.905 13.157 50.875-51.47 18.53 50.28 7.783-11.968 58.062 29.844-36.063 15.97 20.375-.345-46.562 35.94 7.78-11.97-26.905 43.063-41.313-46.063 11.97-18.563-32.907zm-259.218 5.28l91.75 50.94 94.843-.94-81.657-48.218-104.937-1.78zm376.592 47.782l-58.656 20.782 94.094 15.874 62.313-22-97.75-14.656zM81.156 279.063l-45.47 39.75 59.252 11.72 75-47.688-88.782-3.78zm244.22 16.188l78.093 75.875 71.78 10.656-45.125-56.81-104.75-29.72zm-107.22 3.906l-88.97 50.188-58.155 96.906 96.907-58.156 50.22-88.938zm75.47 2.625l15.812 104.158 73.375 76-24.97-108.657-64.218-71.5zm-31.907 40.814l-22 62.344 15.905 94.093 20.78-58.655-14.686-97.78zm-44.19 24.937l-54.374 75.033 6.438 59.25 43.812-45.47 4.125-88.812z",
      },
    ],
  },
  [CustomIcons.CardboardBox]: {
    pathPropsList: [
      {
        d: "M256 73.32L74.6 155.1l82.3 37.1 173.2-85.5L256 73.32zm95.4 42.98l-173.2 85.5 77.8 35.1 181.4-81.8-86-38.8zM61.7 169v182L247 434.6v-182L61.7 169zm388.6 0L265 252.6v182L450.3 351V169z",
      },
    ],
  },
  [CustomIcons.Characters]: {
    pathPropsList: [
      {
        d: "M119.1 25v.1c-25 3.2-47.1 32-47.1 68.8 0 20.4 7.1 38.4 17.5 50.9L99.7 157 84 159.9c-13.7 2.6-23.8 9.9-32.2 21.5-8.5 11.5-14.9 27.5-19.4 45.8-8.2 33.6-9.9 74.7-10.1 110.5h44l11.9 158.4h96.3L185 337.7h41.9c0-36.2-.3-77.8-7.8-111.7-4-18.5-10.2-34.4-18.7-45.9-8.6-11.4-19.2-18.7-34.5-21l-16-2.5L160 144c10-12.5 16.7-30.2 16.7-50.1 0-39.2-24.8-68.8-52.4-68.8-2.9 0-4.7-.1-5.2-.1zM440 33c-17.2 0-31 13.77-31 31s13.8 31 31 31 31-13.77 31-31-13.8-31-31-31zM311 55v48H208v18h103v158h-55v18h55v110H208v18h103v32h80.8c-.5-2.9-.8-5.9-.8-9 0-3.1.3-6.1.8-9H329V297h62.8c-.5-2.9-.8-5.9-.8-9 0-3.1.3-6.1.8-9H329V73h62.8c-.5-2.92-.8-5.93-.8-9 0-3.07.3-6.08.8-9H311zm129 202c-17.2 0-31 13.8-31 31s13.8 31 31 31 31-13.8 31-31-13.8-31-31-31zm0 160c-17.2 0-31 13.8-31 31s13.8 31 31 31 31-13.8 31-31-13.8-31-31-31z",
      },
    ],
  },
  [CustomIcons.ConcentricCrescents]: {
    pathPropsList: [
      {
        d: "M251.19 25.684c-127.62 0-231.272 103.653-231.272 231.27 0 127.62 103.653 231.275 231.27 231.275 127.62 0 231.275-103.657 231.275-231.275 0-127.618-103.656-231.27-231.274-231.27zm0 18.693c117.515 0 212.58 95.063 212.58 212.578s-95.065 212.58-212.58 212.58c-89.756 0-166.407-55.458-197.616-134.017 33.247 63.767 99.96 107.316 176.842 107.316l-.002.004c110.068 0 199.316-89.248 199.316-199.317 0-106.046-82.85-192.754-187.345-198.948 2.92-.118 5.854-.195 8.804-.195zm-25.362 1.508c83.624 11.428 148.188 83.327 148.188 170.062 0 94.667-77.078 171.172-171.746 171.172-76.475 0-141.293-50.118-163.385-119.288-.18-3.603-.274-7.23-.274-10.877 0-14.34 1.426-28.34 4.124-41.877 1.82 83.566 70.14 150.768 154.145 150.768 3.84 0 7.782-.17 11.552-.446-68.53-5.156-122.64-62.572-122.64-132.412 0-73.23 59.625-132.412 132.858-132.412 68.655 0 125.172 52.213 131.97 119.082.15-2.702.444-5.262.444-8 0-85.145-69.038-154.185-154.183-154.185-8.306 0-16.454.663-24.403 1.927 16.828-6.7 34.718-11.306 53.35-13.515zm-6.955 73.553c-2.86 0-5.794.132-8.6.332 51.024 3.838 91.31 46.586 91.31 98.587 0 54.524-44.395 98.588-98.92 98.588-51.118 0-93.194-38.875-98.257-88.664-.112 2.012-.332 3.92-.332 5.956 0 63.396 51.404 114.8 114.8 114.8 63.394 0 114.798-51.404 114.798-114.8 0-63.395-51.404-114.798-114.8-114.798zm-23.328 11.13l-.002.002c-44.363 0-80.336 35.973-80.336 80.336s35.973 80.334 80.336 80.334c2 0 4.055-.094 6.02-.232-35.705-2.686-63.897-32.602-63.897-68.99 0-38.155 31.066-68.99 69.223-68.99 35.77 0 65.215 27.2 68.758 62.042.075-1.407.23-2.74.23-4.166 0-44.363-35.97-80.336-80.333-80.336z",
      },
    ],
  },
  [CustomIcons.DoubleRingedOrb]: {
    pathPropsList: [
      {
        d: "M254.777 93.275c-58.482 0-105.695 47.21-105.695 105.696 0 58.487 47.213 105.698 105.695 105.698 58.482 0 105.696-47.21 105.696-105.697 0-58.48-47.214-105.695-105.696-105.695zm-140.714 63.59C-40.9 155.67-21.26 276.118 227.043 357.748c225.954 74.28 319.04 10.624 239.48-69.973-.413-.55-.84-1.097-1.277-1.64-4.755 3.954-9.71 7.915-14.95 11.88 4.487 5.513 7.138 11.084 7.704 16.01.713 6.2-.9 11.8-6.986 17.977-5.84 5.927-16.25 11.98-32.307 16.49-24.074 5.698-58.427 5.6-102.287-2.656l.105-.04c-2.153-.38-4.3-.787-6.445-1.198-21.875-4.418-46.004-10.805-72.318-19.455-69.962-23-118.054-49.706-146.063-74.936.246-.19.48-.38.728-.568-.27.166-.532.333-.8.5-53.315-48.08-33.682-90.78 46.558-92.2-8.46-.665-16.502-1.016-24.124-1.075zm281.425 0c-7.62.06-15.663.41-24.123 1.076 80.24 1.42 99.86 44.115 46.537 92.193-.264-.165-.513-.33-.78-.494.244.184.472.368.712.553-26.017 23.434-69.357 48.144-131.455 69.973 21.19 5.413 42.82 9.363 64.815 11.64 34.83-15.125 63.025-30.916 84.91-46.554.01.007.02.014.032.02.522-.386 1.03-.773 1.547-1.16 90.502-65.565 69.686-128.11-42.196-127.247zM44.54 286.27c-74.364 73.55-5.467 133.668 176.683 89.125-22.844-7.563-44.89-15.83-65.84-24.194-25.396 2.316-46.41 1.29-62.842-2.346-16.802-4.544-27.613-10.765-33.61-16.852-6.086-6.176-7.697-11.776-6.985-17.977.56-4.88 3.17-10.395 7.582-15.86-5.253-3.968-10.22-7.935-14.986-11.894z",
      },
    ],
  },
  [CustomIcons.Home]: {
    viewBox: "0 0 20 20",
    pathPropsList: [
      {
        d: "M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z",
      },
    ],
  },
  [CustomIcons.Kebab]: {
    viewBox: "0 0 24 24",
    pathPropsList: [
      {
        d: "M14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6Z",
      },
      {
        d: "M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z",
      },
      {
        d: "M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18C10 16.8954 10.8954 16 12 16C13.1046 16 14 16.8954 14 18Z",
      },
    ],
  },
  [CustomIcons.LeaveGame]: {
    viewBox: "0 0 24 24",
    pathPropsList: [
      {
        d: "M8.51428 20H4.51428C3.40971 20 2.51428 19.1046 2.51428 18V6C2.51428 4.89543 3.40971 4 4.51428 4H8.51428V6H4.51428V18H8.51428V20Z",
      },
      {
        d: "M13.8418 17.385L15.262 15.9768L11.3428 12.0242L20.4857 12.0242C21.038 12.0242 21.4857 11.5765 21.4857 11.0242C21.4857 10.4719 21.038 10.0242 20.4857 10.0242L11.3236 10.0242L15.304 6.0774L13.8958 4.6572L7.5049 10.9941L13.8418 17.385Z",
      },
    ],
  },
  [CustomIcons.Profile]: {
    viewBox: "0 0 20 20",
    pathPropsList: [
      {
        d: "M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z",
      },
    ],
  },
  [CustomIcons.Shield]: {
    pathPropsList: [
      {
        d: "M72.02 21.88l-.9 64.34L32.88 108l.93 6.3c18.95 128 92.49 254.1 216.79 369.8l6.4 6 6.4-6c123.2-115.5 195.2-242 214.8-369.6l.9-6.3-38.7-22.21-.8-60.64C320.4 41.15 182 38.72 72.02 21.88zm18.4 21.71c32.58 4.95 64.88 8.72 97.18 11.22v31.85L138.9 115 89.82 86.44zm330.78 3.56l.5 39.29L372.6 115l-49.2-28.6V56.82c32.1-1.63 64.6-4.67 97.8-9.67zm-214.5 9.13c32.5 1.98 65 2.47 97.9 1.42v28.7l-48.9 28.5-49-28.53zM80.22 102.6l49.18 28.6v57.4l-48.38 28.2c-13.02-32.9-22.25-65.9-27.63-99zm351.48 0l26.9 15.3c-5.5 33.1-14.8 66.1-27.6 98.9l-48.5-28.2v-57.4zm-117.7 0l49.1 28.6v57.4L314 217.2l-48.7-28.3v-58zm-116.8.2l48.9 28.4v57.7l-48.7 28.3-49.1-28.6v-57.4zM140 206.2l49.2 28.6v57.4l-52.5 30.6c-18.9-28.7-34.8-57.7-47.68-86.9zm117.2 0l48.7 28.4v57.9l-48.7 28.3-49.2-28.6v-57.4zm116.5 0l49.7 28.9c-12.8 29.3-28.5 58.4-47.1 87.2L325 292.5v-57.9zm-66.5 107.7l40.7 23.6v25.4c-11.9 15.7-24.6 31.3-38.3 46.8-14.4-8.4-28.7-16.9-43.1-25.3v-46.9zm-100.3 0l40.7 23.6v46.8c-14.3 8.4-29.7 17.6-43.5 25.6a816.7 816.7 0 0 1-37.9-45.6v-26.8zm50.2 86.9l26.8 15.6v20.7c-11.3 11.4-19.1 19.2-26.9 27.2-9.3-8.8-18.3-17.7-27-26.6v-21.2z",
      },
    ],
  },
  [CustomIcons.Stress]: {
    pathPropsList: [
      {
        d: "M159.182 17.115l14.882 129.483-46.134-25.303 37.46 72.914c18.642-27.004 49.798-44.702 85.094-44.702 57.07 0 103.334 46.262 103.334 103.332 0 30.772-13.454 58.398-34.797 77.328l67.918 29.404-26.258-60.27 137.83-15.784-126.504-44.65 81.853-53.58H360.1L465.104 18.735 334.8 125.76l-2.978-72.926-69.95 84.832-19.35-84.834-22.323 68.46L159.18 17.116zM24.94 141.8l48.62 54.354 71.725 25.797-51.588-58.237L24.94 141.8zM147.17 254.52l-49.526 27.513 43.69 4.684-76.457 95.18 90.5-48.372-82.702 160.72 124.83-127.95 6.242 71.775 26.863-83.812c-47.03-9.163-82.65-50.216-83.44-99.738zm155.935 87.263c-12.105 7.178-25.8 11.952-40.433 13.672l75.08 136.164-34.647-149.837zM249.95 373.95h-.003l-11.457 59.872 19.237 60.74 12.533-63.812-20.31-56.8zm131.48 13.45l34.513 48.39 52.453 12.493v-.002L435.8 403.183 381.43 387.4z",
      },
    ],
  },
  [CustomIcons.World]: {
    pathPropsList: [
      {
        d: "M253.47 17.406C123.76 17.406 18.437 122.76 18.437 252.47c0 129.707 105.324 235.06 235.03 235.06 129.707 0 235.063-105.353 235.063-235.06 0-129.71-105.355-235.064-235.06-235.064zM367.874 68.75c61.246 38.19 101.97 106.14 101.97 183.72 0 17.143-1.993 33.823-5.75 49.81l-34.25-18.06 22 54.874c-9.454 21.647-22.362 41.432-38 58.687l-43.158-30.936-64.625 47.72-61.656 6.967-13.906-41.78-49.72 26.844-68.093-18.938 9.157 36.594c-28.41-21.793-51.23-50.466-66-83.563L81.25 304.47l32.25 17.124 59.22-9.875 2.843-40.908-37.344-1.718 4.905-17.844 30.78-25.313-25.093-15.625 67.22-38.593-45.345-29.657-66.625 40.187-49.437-15.28c13.812-32.14 35.21-60.22 61.906-82.064l-3.75 44.375 43.376-34.124 72 22.22-22.5-27.407L233 75.562l26.813 28.468 71 9.845-3.5-34.47 41.468 12.657-.905-23.312zm1.156 120.03L278 199.47l28.906 43.218 3.156 64.468L339.25 321l11.438-28.375 62.656 48.656L395.78 294l6.408-48.344-43.75-22.72 10.593-34.155zM221 192.438l-31.594 21.188 36.47 14.78 16.686-14.78L221 192.437zm22.188 144.688l18.687 52.594 19.78-42.564-38.467-10.03z",
      },
    ],
  },
};
interface IconProps {
  icon: CustomIcons;
  size?: string;
  fill?: CustomIconColor | string;
}

export enum CustomIconColor {
  Default = "hsl(218deg 23% 26%)",
  BadNews = "hsla(4.2, 82%, 23.9%, 0.5)",
}

const CustomIcon = ({
  icon,
  size,
  fill = CustomIconColor.Default,
}: IconProps) => {
  const { viewBox, pathPropsList } = Icons[icon];

  const path = pathPropsList.map((pathProps) => (
    <path d={pathProps.d} fill={pathProps.fill ?? fill} />
  ));

  return React.createElement(
    createIcon({
      displayName: icon,
      viewBox: viewBox ?? "0 0 512 512",
      path: path,
      defaultProps: {
        height: size ?? undefined,
        width: size ?? undefined,
      },
    })
  );
};

export default CustomIcon;
